<script lang="ts" setup>
import type { MyAccountCardContainerProp } from './MyAccountCardContainer.props'
import type { MyAccountForYouPlaceholderProps } from './MyAccountForYouPlaceholder.props'

const { wishlistItems, nProducts } = useWishlist()
const { breakpoint } = useProjectBreakpoint()
const { ts, tc } = useI18n()
const { forYou, wishlist: wishlistUrl } = useRouteHelper()
const isMobile = breakpoint.smaller('md')

defineProps<{
  isOverview?: boolean
}>()

const MAX_CARD_NUMBER = computed(() => (isMobile.value ? 3 : 4))

const visibleWishlistItems = computed(() =>
  wishlistItems.value
    .filter(product => product.selectedImage ?? false)
    .slice(0, MAX_CARD_NUMBER.value)
)

const cardExtraItemsClass = computed(() =>
  nProducts.value % 2 === 0 ? '-mx-[1px]' : ''
)

const description = computed(() => {
  return tc('myAccount.wishlist.youHave', nProducts.value)
})

const link = computed(() => {
  return !nProducts.value
    ? { label: '', url: '' }
    : {
        label: ts('myAccount.wishlist.link'),
        url: wishlistUrl,
      }
})

const forYouArgs: MyAccountForYouPlaceholderProps = {
  title: ts('myAccount.forYou.title'),
  message: ts('myAccount.forYou.message'),
  cta: {
    label: ts('myAccount.forYou.link'),
    link: forYou,
  },
}

const cardContainerArgs: MyAccountCardContainerProp = {
  header: {
    title: ts('myAccount.wishlist.title'),
    link: link.value,
  },
  description: description.value,
}
</script>

<template>
  <MyAccountCardContainer v-bind="cardContainerArgs">
    <template #cardContent>
      <div v-if="wishlistItems && wishlistItems.length > 0">
        <div class="text-light-6 flex flex-col gap-6">
          <div class="flex" :class="[isOverview ? 'md:w-[56%]' : 'w-full']">
            <NuxtLink
              v-for="item in visibleWishlistItems"
              :key="item.productSku ?? item.productCode"
              :to="link.url ? link.url : undefined"
              :title="$ts('myAccount.wishlist.link')"
              class="border-primitives-grey-100 border odd:-mx-[1px]"
            >
              <NuxtPicture
                v-if="item.selectedImage"
                provider="cloudinary"
                :src="item.selectedImage"
                :alt="item?.title"
                class="block h-min"
                sizes="75px"
                fit="fill"
                loading="lazy"
              />
            </NuxtLink>
            <span
              v-if="wishlistItems.length > MAX_CARD_NUMBER"
              :class="cardExtraItemsClass"
              class="text-medium-6 border-primitives-grey-100 flex w-1/5 items-center justify-center border border-l-0 pl-px"
            >
              +{{ wishlistItems.length - MAX_CARD_NUMBER }}
            </span>
          </div>
        </div>
      </div>
      <MyAccountForYouPlaceholder v-else v-bind="forYouArgs" />
    </template>
  </MyAccountCardContainer>
</template>
